<template>
  <div class="wrapper">
    <div id="ac-story"
         class="ac-story"
         :style="headerStyle">
      <br><br><br>
      <div class="text-center">
        <h1 class="title">
          <img :src="image_head_title"
               alt="story">
        </h1>
        <h2 class="sub-title">
          {{language.main_story_subTitle}}
        </h2>
        <h4 class="description">
          <br>
          {{language.main_story_description1}}
          <br><br>
          {{language.main_story_description2}}
          <br><br>
          {{language.main_story_description3}}
          <br><br>
          {{language.main_story_description4}}
        </h4>
        <br><br><br>
        <div style="text-align: center">
          <img :src="comingsoon">
        </div>
        <br><br>

      </div>
      <img :src="planet.left_lucy"
           alt="left_lucy"
           class="left_lucy"
           style="opacity: .5;">
      <img :src="planet.left_micky"
           alt="left_micky"
           class="left_micky"
           style="opacity: .5;">
      <img :src="planet.left_mongyi"
           alt="left_mongyi"
           class="left_mongyi"
           style="opacity: .5;">
      <img :src="planet.right_ani"
           alt="right_ani"
           class="right_ani"
           style="opacity: .5;">
      <img :src="planet.right_blue"
           alt="right_blue"
           class="right_blue"
           style="opacity: .5;">
      <img :src="planet.right_pinky"
           alt="right_pinky"
           class="right_pinky"
           style="opacity: .5;">
      <div class="steps-wrap md-layout ob1">
          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-1"><img :src="story.story_01" alt=""></a></div>
          </div>
      </div>
      <div class="steps-wrap md-layout ob2">
          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-2"><img :src="story.story_02" alt=""></a></div>
          </div>
      </div>
      <div class="steps-wrap md-layout ob3">
          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-3"><img :src="story.story_03" alt=""></a></div>
          </div>
      </div>
      <div class="steps-wrap md-layout ob4">

          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-4"><img :src="story.story_04" alt=""></a></div>
          </div>

      </div>
      <div class="steps-wrap md-layout ob5">
          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-5"><img :src="story.story_05" alt=""></a></div>
          </div>
      </div>
      <div class="steps-wrap md-layout ob6">
          <div class="md-layout-item">
              <div class="plan"><a href="javascript:void(0)" class="coloured-img plan-2"><img :src="story.story_06" alt=""></a></div>
          </div>
      </div>
    </div>

    <ACBubbleLink/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Mixins from "@/plugins/basicMixins";
import {
  ACBubbleLink,
} from "@/components";

export default {
  name: "Story",
  mixins: [Mixins.HeaderImage],
  bodyClass: "ac-index",
  components: {
    ACBubbleLink,
  },
  computed: {
    ...mapGetters([
      'language',
    ]),
  },
  data() {
    return {
      image_head_bg: require("@/assets/img/pages/index/02_story_bg.png"),
      image_head_title: require("@/assets/img/pages/nft/anipang_idol_culb_1.png"),
      comingsoon: require("@/assets/img/pages/nft/comingsoon.png"),
      planet: {
        left_lucy: require("@/assets/img/pages/index/left_lucy.png"),
        left_micky: require("@/assets/img/pages/index/left_micky.png"),
        left_mongyi: require("@/assets/img/pages/index/left_mongyi.png"),
        right_ani: require("@/assets/img/pages/index/right_ani.png"),
        right_blue: require("@/assets/img/pages/index/right_blue.png"),
        right_pinky: require("@/assets/img/pages/index/right_pinky.png"),
      },
      story: {
        story_01: require("@/assets/img/pages/index/img_story_01.png"),
        story_02: require("@/assets/img/pages/index/img_story_02.png"),
        story_03: require("@/assets/img/pages/index/img_story_03.png"),
        story_04: require("@/assets/img/pages/index/img_story_04.png"),
        story_05: require("@/assets/img/pages/index/img_story_05.png"),
        story_06: require("@/assets/img/pages/index/img_story_06.png"),
      }
    };
  },
}
</script>

<style lang="scss">

.ac-story h1.title{
    margin-bottom: -22px;
}

.ac-story {
  background-size: cover;

  .sub-title {
    font-weight: 800;
  }

  .description {
    line-height: 1.7em;
    padding: 15px;
    font-size: 1rem;
  }
}

.ac-story img.left_lucy {
    animation-delay: 1s !important;
    animation: left_lucy 2s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes left_lucy {
    from {left: -105%; top: 0%; transform: rotate(360deg) scale(1%);}
    to {left: 18%; top: 50%;}
}

.ac-story img.left_micky {
    animation-delay: 1.5s !important;
    animation: left_micky 2s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes left_micky {
    from {left: -155%; top: -200%; transform: rotate(-720deg) scale(1%);}
    to {left: 7%; top: 24%;}
}

.ac-story img.left_mongyi {
    animation-delay: 1.2s !important;
    animation: left_mongyi 3s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes left_mongyi {
    from {left: -50%; bottom: -35%; transform: rotate(180deg) scale(1%);}
    to {left: -3%; bottom: -5%;}
}

.ac-story img.right_ani {
    animation-delay: 1s !important;
    animation: right_ani 2s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes right_ani {
    from {left: 255%; top: 180%; transform: rotate(-780deg) scale(1%);}
    to {left: 86%; top: 60%;}
}

.ac-story img.right_blue {
    animation-delay: 1.8s !important;
    animation: right_blue 2s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes right_blue {
    from {left: 155%; top: 15%;transform: rotate(180deg) scale(1%);}
    to {left: 70%; top: 38%;}
}

.ac-story img.right_pinky {
    animation-delay: 1.6s !important;
    animation: right_pinky 2s cubic-bezier(0, 0.99, 0.42, 1) forwards !important;
}

@keyframes right_pinky {
    from {right: -355%; top: -315%; transform: rotate(720deg) scale(1%);}
    to {right: 13%; top: 15%;}
}

.ac-story .steps-wrap .md-layout-item {
    height: 210px;
    padding-right: 0; padding-left: 0
}

.ac-story .steps-wrap .md-layout-item.md-small-size-50 .plan {
    width: 70%; height: 70%;
    position: relative
}

.ac-story .steps-wrap .md-layout-item .plan {
    width: 100%; height: 100%;
    position: relative;
}

.ac-story .steps-wrap .md-layout-item .coloured-img {
    position: absolute;
    display: block;
    width: 100%;
}

.ac-story .md-layout {
    display: block;
    position: absolute;
}

.md-layout.ob1{
    animation: fadein 3s;
}

.md-layout.ob2{
    animation: fadein 3s;
}

.md-layout.ob3{
    animation: fadein 3s;
}

.md-layout.ob4{
    animation: fadein 3s;
}

.md-layout.ob5{
    animation: fadein 3s;
}

.md-layout.ob6{
    animation: fadein 3s;
}

@keyframes fadein {
    0% {opacity: 0;}
    25%{opacity: 0;}
    75%{opacity: 0;}
    100%{opacity: 1;}
}

.ob1 {
    top: 10%; left: 19%;
}

.ob2 {
    top: 37%; left: 8%;
}

.ob3 {
    bottom: 15%; left: 21%;
}

.ob4 {
    right: 21%; top: 17%;
}

.ob5 {
    right: 8%; top: 27%;
}

.ob6 {
    bottom: 20%; right: 22%;
}

.steps-wrap .md-layout-item {
    display: inline-block;
}

.ob1 .md-layout-item {
    width: 244px; height: 449px;
}

.ob2 .md-layout-item {
    width: 139px; height: 131px;
}

.ob3 .md-layout-item {
    width: 193px; height: 209px;
}

.ob4 .md-layout-item {
    width: 134px; height: 132px;
}

.ob5 .md-layout-item {
    width: 220px; height: 186px;
}

.ob6 .md-layout-item {
    width: 202px; height: 227px;
}

.ac-story .steps-wrap .md-layout-item .plan-0 {
    left: 0; top: 0;
    -webkit-animation-name: Floatingy2;
    -moz-animation-name: Floatingy2;
    -webkit-animation-duration: 8s;
    -moz-animation-duration: 8s
}

.ac-story .steps-wrap .md-layout-item .plan-1 {
    left: 0; top: 70px;
    -webkit-animation-name: Floatingy2;
    -moz-animation-name: Floatingy2;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s
}

.ac-story .steps-wrap .md-layout-item .plan-2 {
    left: 0; top: 40px;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s
}

.ac-story .steps-wrap .md-layout-item .plan-3 {
    left: 0; top: 80;
    -webkit-animation-name: Floatingy1;
    -moz-animation-name: Floatingy1;
    -webkit-animation-duration: 10s;
    -moz-animation-duration: 10s
}

.ac-story .steps-wrap .md-layout-item .plan-4 {
    left: 0; top: 50px;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    -webkit-animation-duration: 6s;
    -moz-animation-duration: 6s
}

.ac-story .steps-wrap .md-layout-item .plan-5 {
    left: 0; top: 70px;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    -webkit-animation-duration: 10s;
    -moz-animation-duration: 10s
}

.ac-story .coloured-img {
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out
}

@-webkit-keyframes Floatingy {
    0% {-webkit-transform: translate(0)}
    65%{-webkit-transform: translateY(10px)}
    to {-webkit-transform: translate(0)}
}

@-webkit-keyframes Floatingy1 {
    0% {-webkit-transform: translate(0)}
    65%{-webkit-transform: translateY(15px)}
    to {-webkit-transform: translate(0)}
}

@-webkit-keyframes Floatingy2 {
    0% {-webkit-transform: translate(0)}
    65%{-webkit-transform: translateY(10px)}
    to {-webkit-transform: translate(0)}
}

.ac-story .text-center > div {display: none;}

@media (max-width: 980px) {
    .ob1, .ob2, .ob3, .ob4, .ob5, .ob6 {
        display: none  !important;
    }
    
    .ac-story .text-center > div {display: block !important;}

}
</style>